const colors = {
  brandDark: {
    100: "#F2F3F7",
    200: "#CCD1E1",
    300: "#A5AECA",
    400: "#7E8BB4",
    500: "#5B6A9A",
    600: "#445074",
    700: "#2D354D",
    800: "#171B27",
    900: "#08090D",
  },
  brandLight: {
    100: "#EDF5FD",
    200: "#B6D6F7",
    300: "#7EB8F1",
    400: "#4799EB",
    500: "#187ADC",
    600: "#125CA5",
    700: "#0C3D6E",
    800: "#061F37",
    900: "#020A12",
  },
};

export default colors;
